	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/02/2020 00:05
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 16px;
  font-style: normal;
}

.flaticon-share:before { content: "\f100"; }
.flaticon-security:before { content: "\f101"; }
.flaticon-reader:before { content: "\f102"; }
.flaticon-gear:before { content: "\f103"; }
.flaticon-placeholder:before { content: "\f104"; }
.flaticon-browser:before { content: "\f105"; }
.flaticon-user:before { content: "\f106"; }
.flaticon-mail:before { content: "\f107"; }
.flaticon-shut-down:before { content: "\f108"; }
.flaticon-bell:before { content: "\f109"; }
.flaticon-statistics:before { content: "\f10a"; }
.flaticon-spreadsheet:before { content: "\f10b"; }
.flaticon-question:before { content: "\f10c"; }
.flaticon-chat:before { content: "\f10d"; }
.flaticon-phone:before { content: "\f10e"; }
.flaticon-browser-1:before { content: "\f10f"; }
.flaticon-layers:before { content: "\f110"; }
.flaticon-grid:before { content: "\f111"; }
.flaticon-hammer:before { content: "\f112"; }
.flaticon-dashboard:before { content: "\f113"; }
.flaticon-art:before { content: "\f114"; }
.flaticon-list:before { content: "\f115"; }
.flaticon-control:before { content: "\f116"; }
.flaticon-alert:before { content: "\f117"; }
.flaticon-alert-1:before { content: "\f118"; }
.flaticon-toxic:before { content: "\f119"; }
.flaticon-diamond:before { content: "\f11a"; }
.flaticon-star:before { content: "\f11b"; }
.flaticon-share-1:before { content: "\f11c"; }
.flaticon-search:before { content: "\f11d"; }
.flaticon-internet:before { content: "\f11e"; }
.flaticon-paint:before { content: "\f11f"; }
.flaticon-pie-chart:before { content: "\f120"; }
.flaticon-pencil:before { content: "\f121"; }
.flaticon-trash:before { content: "\f122"; }
.flaticon-excel:before { content: "\f123"; }
.flaticon-pdf:before { content: "\f124"; }
.flaticon-network:before { content: "\f125"; }
.flaticon-sticky-note:before { content: "\f126"; }
.flaticon-alarm:before { content: "\f127"; }
.flaticon-archive:before { content: "\f128"; }
.flaticon-email:before { content: "\f129"; }
.flaticon-stats:before { content: "\f12a"; }
.flaticon-conversation:before { content: "\f12b"; }
.flaticon-start:before { content: "\f12c"; }
.flaticon-tick-inside-circle:before { content: "\f12d"; }
.flaticon-information:before { content: "\f12e"; }
.flaticon-turn-notifications-off-button:before { content: "\f12f"; }
.flaticon-reuse:before { content: "\f130"; }
.flaticon-secure-shield:before { content: "\f131"; }
.flaticon-horn:before { content: "\f132"; }
.flaticon-supermarket:before { content: "\f133"; }
.flaticon-computer:before { content: "\f134"; }
.flaticon-like:before { content: "\f135"; }
.flaticon-speech-bubble:before { content: "\f136"; }
.flaticon-paper-plane:before { content: "\f137"; }
.flaticon-menu:before { content: "\f138"; }
